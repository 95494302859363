import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #00afc9;
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
 
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function LiquidFillfaq() {
    return (
        <Section py="4" id="faq" bg="#fff" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
        <Row className="justify-content-center">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">More about Nose Filler</Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
        <Col
     
     >
          
         


           <Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What does the Nose Filler in London involve?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Nose filler, or, as some call it, “the 10-minute nose job”, is a treatment done by injecting Hyaluronic Acid (HA) based dermal fillers into the nose to temporarily tweak its shape. <br /> <br />

Using a very fine needle, dermal filler is strategically injected slowly and carefully in small amounts, into precise points of the nose. The hyaluronic acid filler settles into where it’s injected to hold the shape, instantly modifying the shape of the nose. The patient will be awake and under no general anaesthesia, meaning the patient has a choice to see the results unfold as we administer the injection.




        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>  

<br />


      
<Accordion allowZeroExpanded className="shadow">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How long does the Nose Filler take?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The nose filler takes no longer than 15 minutes; patients will see immediate results, with no recovery time needed, and you can get back to your daily activities or work the very same day

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>  


<br />


      
<Accordion allowZeroExpanded className="shadow">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Does Nose Filler hurt?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        We will apply numbing cream (optional) to the nose before the treatment begins to make your experience as smooth and painless as possible. Only when the numbing cream has taken its full affect do we begin the treatment. Based on our previous patients, many do not feel pain due to the effect of the anaesthetic, others feel mild pain with minimal discomfort.
        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
         
<br />
        
<Accordion allowZeroExpanded className="shadow">
        <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How long is recovery? Will the Nose Filler leave any marks or bruising?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        After the Nose Filler, there is no recovery time needed; you can get straight back to your daily activities or work that does not include any physical activity or sun exposure, the very same day. <br /> <br />

Following the treatment, you will see instant results, with final results taking 2 weeks to allow any minor swelling to settle and for the nose to completely heal redness at the injected site is normal and expected as with all injectable related side effects. <br /> <br />

The treatment is unlikely to leave any visible marks as it is a non-invasive treatment. More on what to expect during and after the treatment will be thoroughly explained during the consultation before the treatment


      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>    
<br />
<Accordion allowZeroExpanded className="shadow">
 
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is the Nose Filler safe?   </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The Nose Filler is done by injecting hyaluronic acid gel into the soft tissue in the nose to shape, contour and straighten the nose. Hyaluronic acid based dermal fillers are derived naturally and not animal based. It is known as one of nature’s most versatile and is made with extensive research in the laboratory. <br /> <br />

Over time the fillers get dissolved naturally in the body as the body is able to break the filler down and results of the treatment gradually fade. The filler breaks down and is re-absorbed by the body, just like the natural occurring hyaluronic acid in the skin. Hyaluronic acid has gained a reputation for being safe, effective and a good alternative to surgery.



        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    
<br /> 


        

<Accordion allowZeroExpanded className="shadow">
  
          <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What happens 12-18 months after my nose filler?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Patients come out of the nose filler treatment feeling more confident, as patients see immediate and natural looking results. Patients will notice their appearance transform soon thereafter. One of the great benefits of nose filler is that it is not a permanent solution, making it ideal for someone wanting to try out changes without committing to surgery or completely altering the face with surgery. As your face changes over time, tweaks can be made to the nose to maintain the result. <br /> <br />

The longevity of the treatment is dependent on a few factors, such as individual’s metabolic rate, lifestyle factors and nasal anatomy. Tweaks can be made as the face changes over time, allowing the maintaining of the result. Most patients come back for a top up treatment after 10-12 months. Some patients return after 2 years as there is often good retention in shape, even requiring less filler at this stage. 


        </p> 
    </AccordionItemPanel>
</AccordionItem>

</Accordion>

<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can Nose Filler make your nose look smaller?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        By strategically injecting in the right points of the nose can give the illusion of it being smaller from the front and side profile. Fillers can plump out points of the nose to alter its shape, changing the angle of the nose and reflection of light. This treatment is best for making minor changes and alterations to the nose, rather than delivering drastic changes like physically reducing the shape of the nose 

        </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion>  



 <br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How much is Nose Filler UK?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        <b>At Dermamina London, the cost of the Liquid Nose Job is a fixed price of £450. The patient is not limited to filler quantity, rather the treatment involves a complete, satisfied result with no limitation in filler quantity. </b> <br /> <br />

        </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is Nose Filler permanent?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        One of the great benefits of nose filler is that it is not a permanent solution, making it ideal for someone wanting to try out changes without committing to surgery or completely altering the face with surgery. As your face changes over time, you can maintain your look with regular dermal filler treatments to plump up and rejuvenate the right areas of the face.

        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How do I prepare for Nose Filler?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        It is best to arrive for your appointment with your skin as best prepared for the treatment. This includes, less makeup on the face, plenty of rest the night before so you are calm and relaxed during the treatment, hydrated and had plenty to eat. Please arrive on time, with all online forms completed and with realistic expectations. Dermal fillers are not intended to completely change your appearance, but enhance what you already have. <br /> <br />

As soon as you book you will be sent pre care advise, included in your form, to help you prepare for the treatment. Our pre care advice includes:<br /> <br />

- Avoid taking any blood thinning medications such as aspirin and ibuprofen for one week prior to your appointment. This is to prevent bruising during and after the treatment. Please consult with your medical practitioner before stopping any medication<br /> <br />

- Avoid booking if you had any major dental treatments or plan to after the treatment. This includes root canal extraction/treatment and deep filing. After 2 weeks it will be fine<br /> <br />

- Arnica tablets can be taken before and after the treatment to reduce the risk of bruising (optional)<br /> <br />

- To lessen the risk of bleeding and bruising, do not consume alcohol 2-3 days prior to your appointment<br /> <br />




        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What should I avoid after Nose Filler?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
    It is important that all patients follow the post treatment advice and to report any side effects and concerns that patients have following the treatment. Post treatment advice will be listed in the forms sent to your email once a treatment has been booked to inform and help you make any preparations necessary.<br /> <br />

An after-care email is also immediately sent right after the treatment to remind you. At Dermamina London we make sure you are in safe hands during and after the treatment. Our post nose filler advice includes:<br /> <br />
- Avoid exercising immediately following treatment for 24 hours<br /> <br />

- Avoid any objects around the nose, including glasses or sunglasses (avoid 6-7 days) and no excessive pressure, touching or rubbing of the areas injected for the first few days<br /> <br />

- There should be no dental treatments for the first few weeks following the treatment. This includes root canal extraction/ treatments or deep filling <br /> <br />

- No alcohol or excessive salts for 24 hours
<br /> <br />
- Avoid applying makeup for 12 hours after the treatment<br /> <br />

- Avoid getting a facial, laser treatment and having hot baths and sauna/ steam room


    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What if I am unhappy with the Nose Filler results?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Nose filler is not permanent, making the treatment ideal for someone wanting to try out changes without committing to surgery or completely altering the nose with surgery, rather it allows the restoring and enhancing of the natural beauty, while stimulating your own collagen in your body. <br /> <br />

The treatment is completely reversible with the use of hyaluronidase. This means that the nose can be bought back to its original shape within days. For those patients that like the result of the nose filler, the treatment can be topped up safely over the years to maintain the result.




      



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Will I get a consultation before the nose filler?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
   

        Yes, all appointment begins with a thorough consultation. At Dermamina London, our lovely practitioner Mina takes a holistic approach, considering patients concerns and expectations and supporting the patient before and after the treatment. <br /> <br />

Dermamina London believes that it is important that patient support starts with pre-treatment consultation, whereby the patient is fully prepared and comfortable in the hands of the practitioner, and most importantly in order to understand the patients drive and expectations. This also includes involving the patient in shared decision making and including the patient in developing individualised care-plan. We want the patient to be as involved as they like and provide feedback throughout in order to achieve the most optimal result.




        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 

<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Am I suitable for Nose Filler?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        At Dermamina, we strive for natural looking results, tailored to individual suitability, aiming to enhance your beauty rather than completely transforming your look. <br /> <br />

Have a look at the vast portfolio of nose filler results to see how many different nose shapes have been transformed with the magic of dermal fillers. We suggest booking in for a consultation if you are unsure whether the treatment will help achieve the results you are looking for. <br /> <br />

You may also get in contact with a member of team and explain your concern with explanations of your nose shape along with what you wish to achieve. It is important to speak with the practitioner during the consultation about your expectations in order to confirm whether or not they are achievable.





        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can I get Nose Filler before a Surgical Rhinoplasty?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, if you are considering rhinoplasty but want to see what the nose could look like before making the big decision, we advise trying nose filler first. Many of the same goals of a rhinoplasty can be achieved with nose filler. Book in for a consultation today to find out what can be achieved. <br /> <br />

If you have had nose filler and are ready for a surgical rhinoplasty, please wait until the fillers have completely dissolved before going ahead with the surgical treatment. If you want the filler to be dissolved, we can help by dissolving the filler with Hyaluronidase treatment. Please contact the clinic to find out more. 





        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can nose filler be done after Surgical Rhinoplasty?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, we have had many patients attend after feeling unsatisfied with the result of a rhinoplasty. We can help fix this by adding filler into the areas of concern. Nose filler can be done after 7 months post rhinoplasty.


        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How many sessions are required to complete the Nose Filler treatment?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        One session is only needed. The nose filler is a non-invasive, 10-minute treatment that provides immediate results. You will see a change in your nose thereafter.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can I have other dermal filler treatments along with Nose Filler?      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, more than one dermal filler treatments can be done all in one day, upon assessment and suitability. Did you know that we offer liquid nose job packages and filler packages? You can choose to add certain amount of filler in the desired areas with professional and honest advice from our lovely practitioner.<br /> <br />



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  




<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Nose Filler in London? Why choose Dermamina London?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        When considering Non-Surgical treatments, it is important to go to a professional and specialised provider that has great knowledge and experience with non-surgical treatments. An experienced and specialised practitioner will know what to do to deliver the desired results while minimizing the risks and side effects and avoiding any complications.<br /> <br />

Dermamina London is renowned for providing natural-looking, yet life changing results. We are committed in providing clinically advanced, evidence based aesthetic treatments, with safety and honesty as our number one priority; we do not recommend any treatments if it is not suitable for you.<br /> <br />

Do not just take our word for it, check out our amazing reviews all left by our valued patients and not to mention the vast portfolio of results all performed by our highly experienced, medically trained, most loving practitioner Mina.






        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion> 

          </Col>




        </Row>



<br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

 


    );
}